import React from 'react'
import logo from '../assets/logo-quero-delivery-roxa-horizontal.png'

export default () => (
    <div style={{ display: 'flex', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: '#FFFFFF', justifyContent: 'center', alignItems: 'center' }}>

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <img src={logo} alt='logo' style={{ width: '40%', height: 'auto', animation: 'cresce-diminui 1s linear infinite' }} />
        </div>
    </div>
)