import numeral from 'numeral'
import VMask from 'vanilla-masker'

export const getQueryStringValue = (name) => {
    const url = window.location.href;
    name = name.replace(/[[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export const objectNotNull = (obj) => {
    const keys = Object.keys(obj).filter(key => (obj[key] !== '' && obj[key] !== ' '))
    const retorno = {}
    keys.forEach(key => retorno[key] = obj[key])
    return retorno

}

export const moneyLabel = (value) => {
    return numeral(value).format('$0,0.00');
}

export const celularLabel = (value) => {
    if (!value) return null;
    value = value.replace(/^55/, '')
    value = removeNoNumeric(value)
    if (value.length === 11) return VMask.toPattern(value, '(99) 9 9999-9999')
    return VMask.toPattern(value, '(99) 9999-9999')
}

export const removeNoNumeric = (val) => {
    if (val === undefined || val === null) return val
    return val.toString().replace(/[^\d]+/g, '')
}