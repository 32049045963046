import './ScreenInitial.scss'

import React from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'semantic-ui-react';

import { setRecebeuPedido } from '../../actions/formActions'
import { SCREENS } from '../../constants'

export default function Home({ screenChange }) {
    const dispatch = useDispatch()

    const pedido = useSelector(state => state.system.pedido)

    return (
        <div className='container-box'>
            <div className='content-initial'>
                <h2>Oi {pedido.nome}</h2>
                <span>Você recebeu o pedido abaixo?</span>

                <div className='btns'>
                    <Button basic color='violet' onClick={() => {
                        dispatch(setRecebeuPedido(true))
                        screenChange(SCREENS.NPS_NOTA)
                    }}><div> Sim <span role="img" aria-label="smile">😀</span> </div></Button>
                    <Button basic color='violet' onClick={() => {
                        dispatch(setRecebeuPedido(false))
                        screenChange(SCREENS.NAO_RECEBEU)
                    }} ><div> Não <span role="img" aria-label="bad">😕</span> </div></Button>
                </div>
            </div>
        </div>
    );
}
