import React from 'react'
import axios from 'axios'
import { API_URL, CookieTokenKey } from '../constants'
import Cookie from 'js-cookie'
import { toast } from 'react-toastify'

const axiosApi = axios

axiosApi.defaults.baseURL = API_URL

export const setBearerToken = (token) => {
    axiosApi.defaults.headers.common['Authorization'] = `Basic ${token}`
}

export const ResponseSucess = function (response) {
    if (response.data) {
        return response.data;
    }
    return response;
}

export const ResponseError = (error) => {
    if (error.response) {

        const { status, data } = error.response

        switch (status) {
            case 400:
                const { errors } = data
                // eslint-disable-next-line no-unused-vars
                const msg = (
                    <div>
                        <strong>[ERR400]:</strong>
                        <div>
                            {
                                errors
                                    .map(error => error.messages.join(' - '))
                                    .map((error, i) => <div key={i}>{error}</div>)
                            }
                        </div>
                    </div>
                )
                break
            case 401:
                Cookie.remove(CookieTokenKey)
                break
            case 403:
                // toast.error(`[403] Sem permissão`)
                Cookie.remove(CookieTokenKey)
                break
            case 503:
                toast.error(errors)
                break;
            default:
                break
        }
    } else {
        if (error.message === 'Network Error') {
            toast.error('📡❗️ Sem conexão com o servidor');
        }
    }

    return Promise.reject(error);
}

axiosApi.interceptors.response.use(ResponseSucess, ResponseError);

export default axiosApi;