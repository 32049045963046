export const FORM_ACTIONS = {
    SET_RECEBEU_PEDIDO: 'SET_RECEBEU_PEDIDO',
    SET_O_QUE_ACONTECEU: 'SET_O_QUE_ACONTECEU',
    SET_PROBLEMA_ESTABELECIMENTO: 'SET_PROBLEMA_ESTABELECIMENTO',
    SET_MOTIVO_CANCELAMENTO: 'SET_MOTIVO_CANCELAMENTO',
    SET_COMENTARIO_FORM: 'SET_COMENTARIO_FORM'
}

export const NPS_ACTIONS = {
    SET_NOTA: 'SET_NOTA',
    SET_COMENTARIO_NPS: 'SET_COMENTARIO_NPS'
}

export const SYSTEM_ACTIONS = {
    SET_TOKEN: 'SET_TOKEN',
    GET_PEDIDO: 'GET_PEDIDO',
    SET_LOADING: 'SET_LOADING'
}