import './ScreenForm.scss'

import React from 'react';
import { Button } from 'semantic-ui-react';
import { useDispatch } from 'react-redux'

import { setOQueAconteceu } from '../../actions/formActions'
import { SCREENS } from '../../constants'

export default function ScreenNaoRecebeu({ screenChange }) {
    const dispatch = useDispatch()

    return (
        <div className='container-box'>
            <div className='content-form'>
                <h2>Não recebeu? O que aconteceu?</h2>
                <span className='span-grey'>Veja se foi algum dos casos abaixo</span>

                <Button className='btns' basic color='violet' onClick={() => {
                    dispatch(setOQueAconteceu('o estabelecimento teve problemas'))
                    screenChange(SCREENS.PROBLEMA_ESTABELECIMENTO)
                }}> O estabelecimento teve problemas </Button>
                <Button className='btns' basic color='violet' onClick={() => {
                    dispatch(setOQueAconteceu('eu cancelei'))
                    screenChange(SCREENS.MOTIVO_CANCELAMENTO)
                }}> Eu cancelei </Button>
                <Button className='btns' basic color='violet' onClick={() => {
                    dispatch(setOQueAconteceu('não sei, até agora espero'))
                    screenChange(SCREENS.POXA_VIDA)
                }}> Não sei, até agora espero... </Button>
            </div>
        </div>
    );
}
