import { FORM_ACTIONS } from "../actions/actionsTypes";

const INITIAL_STATE = {
    respostas: {
        recebeuPedido: null,
        oQueAconteceu: []
    }
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case FORM_ACTIONS.SET_RECEBEU_PEDIDO: {
            return ({
                respostas: {
                    ...state.respostas,
                    recebeuPedido: action.payload
                }
            })
        }
        case FORM_ACTIONS.SET_O_QUE_ACONTECEU: {
            return ({
                respostas: {
                    ...state.respostas,
                    oQueAconteceu: [...state.respostas.oQueAconteceu, action.payload]
                }
            })
        }
        case FORM_ACTIONS.SET_PROBLEMA_ESTABELECIMENTO: {
            return ({
                respostas: {
                    ...state.respostas,
                    oQueAconteceu: [...state.respostas.oQueAconteceu, action.payload]
                }
            })
        }
        case FORM_ACTIONS.SET_MOTIVO_CANCELAMENTO: {
            return ({
                respostas: {
                    ...state.respostas,
                    oQueAconteceu: [...state.respostas.oQueAconteceu, action.payload]
                }
            })
        }
        case FORM_ACTIONS.SET_COMENTARIO_FORM: {
            return ({
                respostas: {
                    ...state.respostas,
                    oQueAconteceu: [...state.respostas.oQueAconteceu, action.payload]
                }
            })
        }
        default:
            return state
    }
}