export const API_URL = process.env.REACT_APP_API_URL
export const CookieTokenKey = 'tq'

export const SCREENS = {
    INITIAL: 'INITIAL',
    NPS_NOTA: 'NPS_NOTA',
    NPS_COMMENT: 'NPS_COMMENT',
    NPS_RESULT: 'NPS_RESULT',
    NAO_RECEBEU: 'NAO_RECEBEU',
    PROBLEMA_ESTABELECIMENTO: 'PROBLEMA_ESTABELECIMENTO',
    MOTIVO_CANCELAMENTO: 'MOTIVO_CANCELAMENTO',
    COMENTARIO: 'COMENTARIO',
    POXA_VIDA: 'POXA_VIDA',
    PEDIDO: 'PEDIDO'
}