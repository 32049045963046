import React from 'react';
import { Button } from 'semantic-ui-react';
import { SCREENS } from '../../constants';

export default function PoxaVida({ screenChange }) {
    return (
        <div className='container-box'>
            <h2>Poxa vida...</h2>

            <span className='span-grey'>Pense numa situação chata... e quando isso acontece a gente não gosta!</span>
            <br />
            <span className='span-grey'>Vamos verificar com o parceiro o que aconteceu, talvez ele deve ter tido problemas e não conseguiu falar com você.</span>
            <br />
            <span className='span-grey'>Iremos fazer de tudo para que isso não aconteça mais!</span>
            <br />
            <br />
            <Button color='violet' onClick={() => screenChange(SCREENS.NPS_NOTA)}>Tudo bem</Button>
        </div>
    );
}
