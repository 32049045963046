import { NPS_ACTIONS } from "../actions/actionsTypes";

const INITIAL_STATE = {
    nps: {
        nota: null,
        comentario: ''
    }
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case NPS_ACTIONS.SET_NOTA: {
            return ({
                nps: {
                    ...state.nps,
                    nota: action.payload
                }
            })
        }
        case NPS_ACTIONS.SET_COMENTARIO_NPS: {
            return ({
                nps: {
                    ...state.nps,
                    comentario: action.payload
                }
            })
        }
        default:
            return state
    }
}