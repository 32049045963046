import './Nps.scss'

import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { SCREENS } from '../../constants';
import { setNotaNps } from '../../actions/npsActions';
import Requests from '../../shared/Requests';

export default function NpsNota({ screenChange }) {
    const dispatch = useDispatch()

    const resultForm = useSelector(state => state.form.respostas)
    const resultNps = useSelector(state => state.nps.nps)

    const [nota, setNota] = useState(null)
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)

    function submitNota() {
        if (nota === null) {
            setError('Por favor, insira um valor')
        } else {
            dispatch(setNotaNps(nota))

            if (nota !== 10) {
                screenChange(SCREENS.NPS_COMMENT)
            } else {
                setLoading(true)

                const payload = {
                    isRecebido: resultForm.recebeuPedido,
                    notaFinal: String(nota),
                    comentarioNota: resultNps.comentario,
                    respostas: resultForm.oQueAconteceu.length > 0 ? resultForm.oQueAconteceu : ""
                }

                Requests.requerimentoCancelamento(payload.isRecebido, payload.notaFinal, payload.comentarioNota, payload.respostas).then(r => {
                    if (r.r) {
                        setLoading(false)
                        screenChange(SCREENS.NPS_RESULT)
                    } else {
                        toast.warn(r.errors.join(' - '))
                        setLoading(false)
                    }
                })
            }
        }
    }

    return (
        <div className='container-box'>
            <div className='content-nota'>
                <h2>Certo, agora sobre o nosso app</h2>
                <span>De 0 a 10, qual a chance de você recomendar o quero delivery a um amigo?</span>

                <div className='btns'>
                    {window.screen.width < 425 ?
                        <div>
                            <div className='btns-mobile'>
                                <Button.Group fluid >
                                    <Button basic={nota === 0 ? false : true} color='violet' onClick={() => {
                                        setError(null)
                                        setNota(0)
                                    }}>0</Button>
                                    <Button basic={nota === 1 ? false : true} color='violet' onClick={() => {
                                        setError(null)
                                        setNota(1)
                                    }}>1</Button>
                                    <Button basic={nota === 2 ? false : true} color='violet' onClick={() => {
                                        setError(null)
                                        setNota(2)
                                    }}>2</Button>
                                    <Button basic={nota === 3 ? false : true} color='violet' onClick={() => {
                                        setError(null)
                                        setNota(3)
                                    }}>3</Button>
                                    <Button basic={nota === 4 ? false : true} color='violet' onClick={() => {
                                        setError(null)
                                        setNota(4)
                                    }}>4</Button>
                                    <Button basic={nota === 5 ? false : true} color='violet' onClick={() => {
                                        setError(null)
                                        setNota(5)
                                    }}>5</Button>
                                </Button.Group>
                            </div>
                            <div className='btns-mobile'>
                                <Button.Group fluid>
                                    <Button basic={nota === 6 ? false : true} color='violet' onClick={() => {
                                        setError(null)
                                        setNota(6)
                                    }}>6</Button>
                                    <Button basic={nota === 7 ? false : true} color='violet' onClick={() => {
                                        setError(null)
                                        setNota(7)
                                    }}>7</Button>
                                    <Button basic={nota === 8 ? false : true} color='violet' onClick={() => {
                                        setError(null)
                                        setNota(8)
                                    }}>8</Button>
                                    <Button basic={nota === 9 ? false : true} color='violet' onClick={() => {
                                        setError(null)
                                        setNota(9)
                                    }}>9</Button>
                                    <Button basic={nota === 10 ? false : true} color='violet' onClick={() => {
                                        setError(null)
                                        setNota(10)
                                    }}>10</Button>
                                </Button.Group>
                            </div>
                        </div>
                        :
                        <Button.Group fluid>
                            <Button basic={nota === 0 ? false : true} color='violet' onClick={() => {
                                setError(null)
                                setNota(0)
                            }}>0</Button>
                            <Button basic={nota === 1 ? false : true} color='violet' onClick={() => {
                                setError(null)
                                setNota(1)
                            }}>1</Button>
                            <Button basic={nota === 2 ? false : true} color='violet' onClick={() => {
                                setError(null)
                                setNota(2)
                            }}>2</Button>
                            <Button basic={nota === 3 ? false : true} color='violet' onClick={() => {
                                setError(null)
                                setNota(3)
                            }}>3</Button>
                            <Button basic={nota === 4 ? false : true} color='violet' onClick={() => {
                                setError(null)
                                setNota(4)
                            }}>4</Button>
                            <Button basic={nota === 5 ? false : true} color='violet' onClick={() => {
                                setError(null)
                                setNota(5)
                            }}>5</Button>
                            <Button basic={nota === 6 ? false : true} color='violet' onClick={() => {
                                setError(null)
                                setNota(6)
                            }}>6</Button>
                            <Button basic={nota === 7 ? false : true} color='violet' onClick={() => {
                                setError(null)
                                setNota(7)
                            }}>7</Button>
                            <Button basic={nota === 8 ? false : true} color='violet' onClick={() => {
                                setError(null)
                                setNota(8)
                            }}>8</Button>
                            <Button basic={nota === 9 ? false : true} color='violet' onClick={() => {
                                setError(null)
                                setNota(9)
                            }}>9</Button>
                            <Button basic={nota === 10 ? false : true} color='violet' onClick={() => {
                                setError(null)
                                setNota(10)
                            }}>10</Button>
                        </Button.Group>
                    }

                    {error && <small>{error}</small>}
                </div>

                <Button color='violet' onClick={() => submitNota()} loading={loading} disabled={loading}>Enviar</Button>
            </div>
        </div>
    );
}

