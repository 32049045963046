import './ScreenForm.scss'

import React, { useState } from 'react';
import { Form, TextArea, Button } from 'semantic-ui-react';
import { useDispatch } from 'react-redux'

import { setComentarioForm } from '../../actions/formActions'
import { SCREENS } from '../../constants'

export default function ScreenComentario({ screenChange }) {
    const dispatch = useDispatch()

    const [comentario, setComentario] = useState('')
    const [error, setError] = useState('')


    function submitForm() {
        if (comentario.length < 5) {
            setError('Digite mais um pouco...')
        } else {
            dispatch(setComentarioForm(comentario))
            screenChange(SCREENS.NPS_NOTA)
        }
    }

    return (
        <div className='container-box'>
            <div className='content-form'>
                <h2>Certo, o que aconteceu?</h2>

                <Form onSubmit={() => submitForm()}>

                    <TextArea autoFocus onChange={(ev, data) => setComentario(data.value)} />
                    {error &&
                        <small>{error}</small>
                    }

                    <div className='btn'>
                        <Button type='submit' color='violet'>Enviar</Button>
                        <span className='link' onClick={() => screenChange(SCREENS.NAO_RECEBEU)}>Voltar</span>
                    </div>
                </Form>
            </div>
        </div>
    );
}
