import './ScreenForm.scss'

import React from 'react';
import { Button } from 'semantic-ui-react';
import { useDispatch } from 'react-redux'

import { setMotivoCancelamento } from '../../actions/formActions'
import { SCREENS } from '../../constants';

export default function ScreenMotivoCancelamento({ screenChange }) {
    const dispatch = useDispatch()

    return (
        <div className='container-box'>
            <div className='content-form'>
                <h2> Qual o motivo do cancelamento? </h2>

                <Button className='btns' basic color='violet' onClick={() => {
                    dispatch(setMotivoCancelamento('fiz o pedido errado'))
                    screenChange(SCREENS.NPS_NOTA)
                }}> Fiz o pedido errado </Button>
                <Button className='btns' basic color='violet' onClick={() => {
                    dispatch(setMotivoCancelamento('tive que sair do local'))
                    screenChange(SCREENS.NPS_NOTA)
                }}> Tive que sair do local </Button>
                <Button className='btns' basic color='violet' onClick={() => {
                    dispatch(setMotivoCancelamento('demorou demais'))
                    screenChange(SCREENS.NPS_NOTA)
                }}> Demorou demais </Button>

                <span className='btn-outro' onClick={() => screenChange(SCREENS.COMENTARIO)}> Outro </span>
            </div>
        </div>
    );
}
