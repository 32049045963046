import React, { useState } from 'react';
import { TextArea, Form, Button } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { SCREENS } from '../../constants'
import { setComentarioNps } from '../../actions/npsActions';
import Requests from '../../shared/Requests';

export default function NpsComment({ screenChange }) {
    const dispatch = useDispatch()

    const nota = useSelector(state => state.nps.nps.nota)
    const resultForm = useSelector(state => state.form.respostas)
    const resultNps = useSelector(state => state.nps.nps)

    const [comentario, setComentario] = useState('')
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)

    function submitForm() {
        if (comentario.length < 5) {
            setError('Digite mais um pouco...')
        } else {
            dispatch(setComentarioNps(comentario))

            setLoading(true)

            const payload = {
                isRecebido: resultForm.recebeuPedido,
                notaFinal: String(resultNps.nota),
                comentarioNota: comentario,
                respostas: resultForm.oQueAconteceu.length > 0 ? resultForm.oQueAconteceu : ""
            }
            
            Requests.requerimentoCancelamento(payload.isRecebido, payload.notaFinal, payload.comentarioNota, payload.respostas).then(r => {
                if (r.r) {
                    setLoading(false)
                    screenChange(SCREENS.NPS_RESULT)
                } else {
                    toast.warn(r.errors.join(' - '))
                    setLoading(false)
                }
            })
        }
    }

    return (
        <div className='container-box'>
            <div className='content-comment'>
                <h2>Agora sobre o nosso app</h2>

                <span>De 0 a 10, qual a chance de você recomendar o quero delivery a um amigo?</span>
                <span className='span-grey'>{`Você deu nota ${nota}`}</span>
                <span>O que falta para chegarmos numa nota 10?</span>

                <Form onSubmit={() => submitForm()}>
                    <TextArea autoFocus onChange={(ev, data) => setComentario(data.value)} />
                    {error &&
                        <small>{error}</small>
                    }

                    <div className='btn'>
                        <Button type='submit' color='violet' loading={loading} disabled={loading}>Enviar</Button>
                        <span className='link' onClick={() => screenChange(SCREENS.NPS_NOTA)}>Voltar</span>
                    </div>
                </Form>

            </div>
        </div>
    );
}
