import { combineReducers } from 'redux';

import formReducers from './formReducers'
import npsReducers from './npsReducers'
import systemReducers from './systemReducers'

const reducers = combineReducers({
  form: formReducers,
  nps: npsReducers,
  system: systemReducers
});

export default reducers;