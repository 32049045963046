import React from 'react';
import { useSelector } from 'react-redux'

export default function NpsResult() {
    const pedido = useSelector(state => state.system.pedido)

    return (
        <div className='container-box'>
            <div className='container-result'>
                <h2>Muito obrigado {pedido.nome}</h2>
                <span className='span-grey'>Olha, agradecemos demais o feedback, isso vai nos ajudar a está sempre melhorando a experiência dentro do roxinho</span>

                <div className='images'>
                    <span className='smile' role='img' aria-label="smile"> 😄 </span>
                    <span className='coracao' role='img' aria-label='coracao'> 💜 </span>
                </div>
            </div>
        </div>
    );
}
