import { FORM_ACTIONS } from './actionsTypes'

export const setRecebeuPedido = (resp) => {
    return (dispatch) => {
        dispatch({
            type: FORM_ACTIONS.SET_RECEBEU_PEDIDO,
            payload: resp
        })
    }
}

export const setOQueAconteceu = (resp) => {
    return (dispatch) => {
        dispatch({
            type: FORM_ACTIONS.SET_O_QUE_ACONTECEU,
            payload: resp
        })
    }
}

export const setProblemaEstabelecimento = (resp) => {
    return (dispatch) => {
        dispatch({
            type: FORM_ACTIONS.SET_PROBLEMA_ESTABELECIMENTO,
            payload: resp
        })
    }
}

export const setMotivoCancelamento = (resp) => {
    return (dispatch) => {
        dispatch({
            type: FORM_ACTIONS.SET_MOTIVO_CANCELAMENTO,
            payload: resp
        })
    }
}

export const setComentarioForm = (resp) => {
    return (dispatch) => {
        dispatch({
            type: FORM_ACTIONS.SET_COMENTARIO_FORM,
            payload: resp
        })
    }
}