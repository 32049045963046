import './Pedido.scss'

import React from 'react';
import { Image, Loader } from 'semantic-ui-react';
import { useSelector } from 'react-redux'
import moment from 'moment'

import { Separator } from '../../shared/SharedComponets'
import { moneyLabel, celularLabel } from '../../helpers/FnUtils'

export default function Pedido() {
    const pedido = useSelector(state => state.system.pedido)
    const loading = useSelector(state => state.system.loadingPedido)

    return (
        <div className='container-pedido'>
            <div className='pedido'>
                {loading ?
                    <Loader active inline size='big' />
                    :
                    <>
                        <h2>Pedido</h2>
                        <span className='cod-pedido'>{pedido.codigo}</span>
                        <span className='dia-horario'>{moment(pedido.createdAt).format('dddd DD/MM/YYYY HH:mm')}</span>

                        <Image avatar src={pedido.place && pedido.place.avatar.src} size="tiny" />

                        <span className='nome-estabelecimento'>{pedido.place && pedido.place.nomeExibicao}</span>

                        <span className='nome-parceiro'>{pedido.nome}</span>
                        <span className='telefone'>{celularLabel(pedido.celular)}</span>


                        <div className='produtos'>
                            <div className="pedido-totais-container">
                                <div className="preco-container">
                                    <small>Qtd. Produtos</small>
                                    <h5>{pedido && pedido.produtos && pedido.produtos.length}</h5>
                                </div>
                                {pedido && pedido.entrega && !pedido.entrega.isRetirada ?
                                    <div className="preco-container">
                                        <small>Preço Entrega</small>
                                        <h5>{moneyLabel(pedido.entrega.preco)}</h5>
                                    </div>
                                    : null}
                                <div className="preco-container">
                                    <small>Preço Produtos</small>
                                    <h5>{moneyLabel(pedido.precoProdutos)}</h5>
                                </div>
                                <div className="preco-container">
                                    <small>Preço Total</small>
                                    <h5 className="color-brand text-danger">{moneyLabel(pedido.precoTotal)}</h5>
                                </div>

                            </div>

                            <Separator />

                            {pedido.produtos &&
                                pedido.produtos.map(produto => (
                                    <div key={produto._id}>
                                        <div className="produto-container">
                                            <span className="produto-qtd">
                                                {produto.quantidade}{produto.isPesavel && <small className='text-muted'>kg</small>}x
                                </span>
                                            <div className="center-container">
                                                <h5 className='produto-nome'>
                                                    <span>{produto.nome}</span>
                                                    {(produto.variacoes.length > 0) &&
                                                        (
                                                            <span className="produto-preco">(R${produto.preco})</span>
                                                        )}
                                                </h5>
                                                {(produto.variacoes.length > 0) && (
                                                    <div className="produto-variacoes">
                                                        {produto.variacoes.map(variacao => (
                                                            <div key={variacao._id} className="produto-variacao">
                                                                <span className="produto-variacao-nome">{variacao.nome}</span>
                                                                <span className="produto-variacao-tipo-calculo">({variacao.tipoCalculo})</span>
                                                                <ul>
                                                                    {variacao.opcoes.map(opcao => (
                                                                        <li key={opcao.produtoOpcaoId} className="produto-opcao">
                                                                            <span className="produto-opcao-nome">
                                                                                {opcao.nome}
                                                                            </span>
                                                                            <span className="produto-opcao-preco">(R${opcao.preco})</span>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                                {produto.observacao && (
                                                    <div className="produto-observacao">
                                                        <span>
                                                            {produto.observacao}
                                                        </span>

                                                    </div>
                                                )}
                                            </div>
                                            <div className="preco-container">
                                                <span className="preco-unitario">{produto.quantidade}x {moneyLabel(produto.precoUnitario)}</span>
                                                <span className="preco-total">{moneyLabel(produto.precoTotal)}</span>
                                            </div>
                                        </div>

                                        <Separator />
                                    </div>
                                ))
                            }
                        </div>
                    </>
                }
            </div>
        </div >
    );
}
