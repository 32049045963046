import './ScreenForm.scss'

import React from 'react';
import { Button } from 'semantic-ui-react';
import { useDispatch } from 'react-redux'

import { setProblemaEstabelecimento } from '../../actions/formActions'
import { SCREENS } from '../../constants';

export default function ScreenProblemaEstabelecimento({ screenChange }) {
    const dispatch = useDispatch()

    return (
        <div className='container-box'>
            <div className='content-form'>
                <h2> Qual problema o estabelecimento teve?</h2>

                <Button className='btns' basic color='violet' onClick={() => {
                    dispatch(setProblemaEstabelecimento('não iria conseguir entregar'))
                    screenChange(SCREENS.NPS_NOTA)
                }}> Não iria conseguir entregar </Button>
                <Button className='btns' basic color='violet' onClick={() => {
                    dispatch(setProblemaEstabelecimento('algum produto em falta'))
                    screenChange(SCREENS.NPS_NOTA)
                }}> Algum produto em falta </Button>
                <Button className='btns' basic color='violet' onClick={() => {
                    dispatch(setProblemaEstabelecimento('eles não informaram'))
                    screenChange(SCREENS.NPS_NOTA)
                }}> Eles não informaram </Button>

                <span className='btn-outro' onClick={() => screenChange(SCREENS.COMENTARIO)}> Outro </span>
            </div>
        </div>
    );
}
