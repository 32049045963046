import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import Home from './pages/home/Home'
import TopBar from './pages/topBar/TopBar'
import ScreenLinkExpirate from './shared/ScreenLinkExpirate'
import ScreenLoader from './shared/ScreenLoader'

import { setToken } from './actions/systemActions';


function App() {
    const dispatch = useDispatch()

    const token = useSelector(state => state.system.token)
    const loading = useSelector(state => state.system.loading)

    useEffect(() => {
        dispatch(setToken())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if(loading) return <ScreenLoader />

    return (
        <BrowserRouter>
            <Switch>
                <Route path='/' render={() => (
                    <>
                        <TopBar />
                        {!token ?
                            <ScreenLinkExpirate />
                            :
                            <Route path='/' component={Home} />
                        }

                    </>
                )} />
            </Switch>
        </BrowserRouter>
    );
}

export default App;
