import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { applyMiddleware, createStore } from 'redux'
import promise from 'redux-promise'
import thunk from 'redux-thunk'
import { ToastContainer, cssTransition } from 'react-toastify';

import reducers from './reducers'
import App from './App';

import 'react-toastify/dist/ReactToastify.css'
import 'semantic-ui-css/semantic.min.css'
import './styles/default.scss'
import './styles/style.scss'
import './styles/style-mobile.scss'

import moment from 'moment-timezone';
import 'moment-precise-range-plugin';
import 'moment/locale/pt-br'
moment.locale('pt-br');
moment.tz.setDefault("America/Bahia");

const devTools = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION__
    && window.__REDUX_DEVTOOLS_EXTENSION__() : {}

export const store = applyMiddleware(thunk, promise)(createStore)(reducers, devTools)

const Zoom = cssTransition({
    enter: 'zoomIn',
    exit: 'zoomOut',
    duration: 300,
});

ReactDOM.render(
    <Provider store={store}>
        <App />
        <ToastContainer
                closeButton={false}
                autoClose={3000}
                newestOnTop={true}
                transition={Zoom}
                position='bottom-center'
                progressClassName='toast-progress'
                hideProgressBar={true}
                toastClassName='toast'
                pauseOnVisibilityChange={false}
            />
    </Provider>,
    document.getElementById('root')
);
