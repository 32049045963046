import './TopBar.scss'

import React from 'react';

import logo from '../../assets/logo-quero-delivery-roxa-horizontal.png'

export default function TopBar() {

    return (
        <div className='top-bar'>
            <img className='logo' src={logo} alt='logo-qd' />
        </div>
    );
}
