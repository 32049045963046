import React from 'react';

export default function shared() {
    return (
        <div className='container'>
            <div className='container-box'>
                <h2>Ops!</h2>
                <span>O link informado está expirado! <span role='img' aria-label="bad">😕</span> </span>
            </div>
        </div>
    );
}
