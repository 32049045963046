import { SYSTEM_ACTIONS } from "../actions/actionsTypes";

const INITIAL_STATE = {
    token: null,
    pedido: {},
    loading: true
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case SYSTEM_ACTIONS.SET_LOADING: {
            return ({
                ...state,
                loading: action.payload
            })
        }
        case SYSTEM_ACTIONS.SET_TOKEN: {
            return ({
                ...state,
                token: action.payload
            })
        }
        case SYSTEM_ACTIONS.GET_PEDIDO: {
            return ({
                ...state,
                pedido: action.payload
            })
        }
        default:
            return state
    }
}