import React from 'react'

export const Separator = (props) => {
    const classes = ['separator']

    if (props.centered) classes.push('center')

    return (
        <div className="separator" style={props.style}></div>
    )
}