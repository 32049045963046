import AxiosApi from './../shared/AxiosApi'
import { objectNotNull } from '../helpers/FnUtils'

const Requests = {
    getToken: (tokenAccess) => {
        return AxiosApi.get(`/usuarios/token-access-requerimento-cancelamento?tokenAccess=${tokenAccess}`)
    },
    getPedido: () => {
        return AxiosApi.get(`/pedidos/requerimento-cancelamento`)
    },
    requerimentoCancelamento: (isRecebido, notaFinal, comentarioNota, respostas) => {
        return AxiosApi.post(`/pedidos/responder-requerimento-cancelamento`,objectNotNull({isRecebido, notaFinal, comentarioNota, respostas}))
    }
}

export default Requests