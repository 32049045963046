import { NPS_ACTIONS } from './actionsTypes'

export const setNotaNps = (nota) => {
    return (dispatch) => {
        dispatch({
            type: NPS_ACTIONS.SET_NOTA,
            payload: nota
        })
    }
}

export const setComentarioNps = (comentario) => {
    return (dispatch) => {
        dispatch({
            type: NPS_ACTIONS.SET_COMENTARIO_NPS,
            payload: comentario
        })
    }
}