import React, { useState } from 'react';

import { SCREENS } from '../../constants'

import ScreenInitial from '../screenInitial/ScreenInitial'
import NpsNota from '../nps/NpsNota'
import NpsComentario from '../nps/NpsComment';
import NpsResult from '../nps/NpsResult';
import ScreenNaoRecebeu from '../screensForm/ScreenNaoRecebeu';
import PoxaVida from '../screenPoxaVida/PoxaVida';
import ScreenMotivoCancelamento from '../screensForm/ScreenMotivoCancelamento';
import ScreenProblemaEstabelecimento from '../screensForm/ScreenProblemaEstabelecimento';
import ScreenComentario from '../screensForm/ScreenComentario';

import Pedido from '../pedido/Pedido'

export default function Home() {
    const [screen, setScreen] = useState(SCREENS.INITIAL)


    const screenChange = (screen) => {
        setScreen(screen)
    }

    switch (screen) {
        case SCREENS.INITIAL:
            return (
                <div className='container'>
                    <ScreenInitial screenChange={screenChange} />
                    <Pedido />
                </div>
            )
        case SCREENS.NPS_NOTA:
            return (
                <div className='container'>
                    <NpsNota screenChange={screenChange} />
                    <Pedido />
                </div>
            )
        case SCREENS.NPS_COMMENT:
            return (
                <div className='container'>
                    <NpsComentario screenChange={screenChange} />
                    <Pedido />
                </div>
            )
        case SCREENS.NPS_RESULT:
            return (
                <div className='container'>
                    <NpsResult />
                    <Pedido />
                </div>
            )
        case SCREENS.POXA_VIDA:
            return (
                <div className='container'>
                    <PoxaVida screenChange={screenChange} />
                    <Pedido />
                </div>
            )
        case SCREENS.NAO_RECEBEU:
            return (
                <div className='container'>
                    <ScreenNaoRecebeu screenChange={screenChange} />
                    <Pedido />
                </div>
            )
        case SCREENS.MOTIVO_CANCELAMENTO:
            return (
                <div className='container'>
                    <ScreenMotivoCancelamento screenChange={screenChange} />
                    <Pedido />
                </div>
            )
        case SCREENS.PROBLEMA_ESTABELECIMENTO:
            return (
                <div className='container'>
                    <ScreenProblemaEstabelecimento screenChange={screenChange} />
                    <Pedido />
                </div>
            )
        case SCREENS.COMENTARIO:
            return (
                <div className='container'>
                    <ScreenComentario screenChange={screenChange} />
                    <Pedido />
                </div>
            )
        default:
            return null
    }
}
