import Cookie from 'js-cookie'

import { setBearerToken } from '../shared/AxiosApi'
import { SYSTEM_ACTIONS } from './actionsTypes'
import Requests from '../shared/Requests'
import { CookieTokenKey } from '../constants'

export const setToken = () => {
    return async (dispatch) => {

        const accessToken = window.location.pathname.replace(/\//, '')

        await dispatch({
            type: SYSTEM_ACTIONS.SET_LOADING,
            payload: true
        })

        if(accessToken) {
            Requests.getToken(accessToken).then(r => {
                if(r.r){
                    setBearerToken(r.data)
                    Cookie.set(CookieTokenKey, r.data)
    
                    dispatch({
                        type: SYSTEM_ACTIONS.SET_TOKEN,
                        payload: r.data
                    })
    
                    dispatch(getPedido())
                } else {
                    dispatch({
                        type: SYSTEM_ACTIONS.SET_LOADING,
                        payload: false
                    })
                }
            }).catch( err => {
                dispatch({
                    type: SYSTEM_ACTIONS.SET_LOADING,
                    payload: false
                })
            })
        } else {
            dispatch({
                type: SYSTEM_ACTIONS.SET_LOADING,
                payload: false
            })
        }
    }
}

export const getPedido = () => {
    return (dispatch) => {
        Requests.getPedido().then(r => {
            if(r.r){
                dispatch({
                    type: SYSTEM_ACTIONS.GET_PEDIDO,
                    payload: r.data
                })

                dispatch({
                    type: SYSTEM_ACTIONS.SET_LOADING,
                    payload: false
                })
            } else {
                dispatch({
                    type: SYSTEM_ACTIONS.SET_LOADING,
                    payload: false
                })
            }
        }).catch(err => {
            dispatch({
                type: SYSTEM_ACTIONS.SET_TOKEN,
                payload: null
            })

            dispatch({
                type: SYSTEM_ACTIONS.SET_LOADING,
                payload: false
            })
        })
    }
}